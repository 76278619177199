<template>
  <div class="logincomp">
    <el-form ref="LoginForm" :model="state">
      <el-form-item
        :rules="[
          { required: true, message: '请输入登录账号！', trigger: 'blur' },
        ]"
        label="登录账号"
        prop="phone"
      >
        <el-input
          v-model.trim="state.phone"
          clearable
          placeholder="请输入账号"
          type="text"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        :rules="[
          { required: true, message: '请输入登录密码！', trigger: 'blur' },
        ]"
        label="登录密码"
        prop="pwd"
      >
        <el-input
          v-model.trim="state.pwd"
          clearable
          placeholder="请输入密码"
          show-password
          type="password"
        >
        </el-input>
      </el-form-item>
      <el-checkbox v-model="state.rememberMe">记住我</el-checkbox>
    </el-form>
    <br />
    <p class="btn" @click="login()">登录</p>
  </div>
</template>

<script>
import { useStore } from "vuex";
import * as Vue from "vue";
import api from "@/comment/request/api";
import router from "@/router";
import { ElMessage } from "element-plus";
import { getCurrentInstance } from "vue";
export default {
  name: "LoginComp",
  components: {},
  props: ["activeName"],
  setup(props, { attrs, slots, emit }) {
    const store = useStore();
    const state = Vue.reactive({
      phone: "",
      pwd: "",
      rememberMe: false,
      state: null,
    });
    const { proxy } = getCurrentInstance();
    Vue.watch(
      () => props.activeName,
      () => {
        if (localStorage.getItem("IsChecked")) {
          const userInfo = localStorage.getItem("IsChecked");
          state.phone = JSON.parse(userInfo).phone;
          state.pwd = JSON.parse(userInfo).pwd;
        }
      },
      { deep: true }
    );

    /*如果记住密码，就将账号密码放入输入框*/
    if (
      localStorage.getItem("gx_checked") === "true" &&
      localStorage.getItem("IsChecked")
    ) {
      state.rememberMe = true;
      const userInfo = localStorage.getItem("IsChecked");
      state.phone = JSON.parse(userInfo).phone;
      state.pwd = JSON.parse(userInfo).pwd;
    } else {
      state.rememberMe = false;
      localStorage.setItem("gx_checked", "false");
    }

    /*第三方登录带参数--错误提示*/
    if (router.currentRoute.value.query.error === "4001") {
      let params = router.currentRoute.value.query;
      ElMessage({
        message: params.description,
        type: "error",
      });
      state.state = params.state;
    }
    if (router.currentRoute.value.query.state) {
      let params = router.currentRoute.value.query;
      state.state = params.state;
    }

    const handleClose = () => {
      emit("handleClose");
    };
    const login = () => {
      const params = {
        phone: state.phone,
        pwd: state.pwd,
        state: null,
        rememberMe: state.rememberMe,
      };
      if (router.currentRoute.value.query.state) {
        params.state = router.currentRoute.value.query.state;
      }
      proxy.$refs["LoginForm"].validate((valid) => {
        if (valid) {
          api.Login(params).success((res) => {
            if (res.data.code === 200) {
              ElMessage({
                message: "登录成功",
                type: "success",
              });
              /*如果有重定向location*当前页跳转*/
              if (res.data.data.location) {
                window.open(res.data.data.location, "_self");
              }
              localStorage.setItem("gx_user", JSON.stringify(res.data.data));
              store.commit("setUserInfo", res.data.data.user);
              /*改变登录状态*/
              store.commit("setIsLogin", true);
              window.localStorage.setItem("gx_centerTabIndex", "2");
              router.push({ name: "PersonalCenter" });

              /*登录成功后保存数据*/
              if (state.rememberMe) {
                localStorage.setItem("IsChecked", JSON.stringify(params));
                localStorage.setItem("gx_checked", "true");
                /*如果是信息申报页面，刷新一下*/
                if (store.state.reload === true) {
                  window.location.reload();
                  setTimeout(() => {
                    store.commit("setReload", false);
                  }, 1000);
                }
              } else {
                localStorage.removeItem("IsChecked");
              }
            } else {
              ElMessage({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        }
      });
    };
    const ChangeLoginState = () => {
      emit("ChangeLoginState", true);
      handleClose();
    };

    return {
      state,
      props,
      login,
      ChangeLoginState,
      handleClose,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.logincomp {
  .btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    display: inline-block;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background-color: #7f0404;
    text-align: center;
    cursor: pointer;
  }
  /deep/ .el-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 22px;
  }
  /deep/ .el-form-item__label {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-right: 20px;
  }
  /deep/ .el-form-item__content {
    width: 100%;
  }
  a {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7f0404;
    display: block;
    text-align: center;
    margin-top: 10px;
  }

  /deep/ .el-input__inner {
    border: none;
    outline: none;
    padding: 0;
    font-size: 24px;
    border-bottom: 1px dashed #eee;
    color: #333333;
    width: 100%;
    height: 40px;
    line-height: 40px;
  }

  /deep/ .el-input__inner::placeholder,
  .password::placeholder {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.3);
  }
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #7f0404;
  border-color: #7f0404;
}

/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  //color: #595959;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
/deep/input:focus {
  box-shadow: none;
}
</style>
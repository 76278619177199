<template>
  <div class="login-page-box">
    <div class="Content">
      <div>
        <el-tabs v-model="state.activeName" @tab-click="handleTabClick">
          <el-tab-pane label="登录" name="login">
            <LoginComp :activeName="state.activeName"></LoginComp>
          </el-tab-pane>
          <el-tab-pane label="注册" name="register">
            <RegisterComp @ChangeActive="ChangeActive"></RegisterComp>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import LoginComp from "@/views/Home/Components/LoginComp";
import RegisterComp from "@/views/Home/Components/RegisterComp";
export default {
  name: "LoginPage",
  components: { LoginComp, RegisterComp },
  props: [],
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      activeName: "login",
    });

    if (router.currentRoute.value.params.active) {
      state.activeName = router.currentRoute.value.params.active;
    }
    const handleTabClick = (tab) => {
      state.activeName = tab.props.name;
    };
    const ChangeActive = (value) => {
      state.activeName = value;
    };

    return {
      state,
      props,
      handleTabClick,
      ChangeActive,
    };
  },
};
</script>

<style lang="scss" scoped>
.login-page-box {
  width: 900px;
  height: 600px;
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  padding-bottom: 40px;
  position: relative;
  .Content {
    width: 900px;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    > div:nth-child(1) {
      margin-top: 40px;
    }
    > div:nth-child(2) {
      margin-top: 20px;
    }
  }
}

/*tabs样式*/
/deep/ .el-tabs__item.is-active {
  color: #7f0404 !important;
}

/deep/ .el-tabs__item {
  height: 50px;
  line-height: 36px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #666666 !important;
}

/deep/ .el-tabs__item:hover {
  color: #7f0404;
}

/deep/ .el-tabs__active-bar {
  background-color: #7f0404;
}

/deep/ .el-tabs__active-bar {
  height: 4px !important;
}
</style>
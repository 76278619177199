<template>
  <div>
    <el-form ref="RegisterForm" :model="state">
      <div class="register">
        <div class="type">
          <div>
            <el-form-item
              :rules="[
                {
                  required: true,
                  message: '请选择用户类型！',
                  trigger: 'blur',
                },
              ]"
              label="用户类型"
              prop="radio"
              style="display: flex; flex-direction: row"
            >
              <el-radio-group v-model="state.radio">
                <el-radio :label="0">个人用户</el-radio>
                <el-radio :label="1">企业用户</el-radio>
                <el-radio :label="2">机构用户</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
        <div>
          <el-form-item
            :rules="[
              { required: true, message: '请输入用户名！', trigger: 'blur' },
              { validator: validUserName, trigger: 'blur' },
            ]"
            label="请输入用户名"
            prop="phone"
          >
            <el-input
              v-model.trim="state.phone"
              clearable
              placeholder="请输入用户名"
              type="text"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            :rules="[
              { required: true, message: '请输入密码！', trigger: 'blur' },
              { validator: validPassword, trigger: 'blur' },
            ]"
            label="请输入密码"
            prop="pwd"
          >
            <el-input
              v-model.trim="state.pwd"
              clearable
              placeholder="请输入密码"
              show-password
              type="password"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            :rules="[
              { required: true, message: '请再次输入密码！', trigger: 'blur' },
              { validator: validPassword, trigger: 'blur' },
            ]"
            label="请再次输入密码"
            prop="rePassword"
          >
            <el-input
              v-model.trim="state.rePassword"
              clearable
              placeholder="请输入密码"
              show-password
              type="password"
            >
            </el-input>
          </el-form-item>
        </div>
        <div>
          <p class="btn" @click="register">注册</p>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import * as Vue from "vue";
import api from "@/comment/request/api";
import { ElMessage } from "element-plus";
import { getCurrentInstance } from "vue";
export default {
  name: "RegisterComp",
  components: {},
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      radio: 0,
      phone: "",
      pwd: "",
      rePassword: "",
    });
    const { proxy } = getCurrentInstance();
    const register = () => {
      const params = {
        phone: state.phone.trim(),
        pwd: state.pwd.trim(),
        type: state.radio,
      };
      if (params.phone === "" || params.pwd === "") {
        ElMessage.error("账号密码不可为空！");
      } else {
        if (state.pwd.trim() !== state.rePassword.trim()) {
          ElMessage.error("两次密码不一致");
        } else {
          proxy.$refs["RegisterForm"].validate((valid) => {
            if (valid) {
              RegForm(params);
            }
          });
        }
      }
    };
    const RegForm = (params) => {
      api.Register(params).success((res) => {
        if (res.data.code === 0) {
          ElMessage({
            message: "注册成功",
            type: "success",
          });
          // store.emit('IsLogin',true)/*登录状态*/
          localStorage.setItem("gx_checked", "false"); /*默认不记住密码*/
          localStorage.setItem(
            "IsChecked",
            JSON.stringify(params)
          ); /*存登录账号去登录*/
          emit("ChangeActive", "login");
        } else {
          ElMessage({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    };
    const validUserName = (rule, value, callback) => {
      const usrName = /^[a-zA-Z0-9_]{6,16}$/;
      if (!value) {
        callback();
      }
      setTimeout(() => {
        if (usrName.test(value)) {
          callback();
        } else {
          callback(
            new Error(
              "用户账号长度在6-16之间，允许字母数字下划线！"
            )
          );
        }
      }, 100);
    };
    const validPassword = (rule, value, callback) => {
      const passWord = /^[a-zA-Z0-9_]\w{6,16}$/;
      if (!value) {
        callback();
      }
      setTimeout(() => {
        if (passWord.test(value)) {
          callback();
        } else {
          callback(
            new Error(
              "用户密码长度在6~16之间，只能包含字母、数字和下划线!"
            )
          );
        }
      }, 100);
    };

    return {
      state,
      radio: state.radio,
      register,
      validUserName,
      validPassword,
      RegForm,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.register {
  .btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    display: inline-block;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background-color: #7f0404;
    text-align: center;
    cursor: pointer;
  }

  /deep/ .el-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 22px;
  }

  /deep/ .el-form-item__label {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-right: 20px;
  }

  /deep/ .el-form-item__content {
    width: 100%;
  }

  /deep/ .el-input__inner {
    border: none;
    outline: none;
    padding: 0;
    font-size: 24px;
    border-bottom: 1px dashed #eee;
    color: #333333;
    width: 100%;
    height: 40px;
    line-height: 40px;
  }

  /deep/ .el-input__inner::placeholder,
  .password::placeholder {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.3);
  }

  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #7f0404;
    background: #ffffff;
  }

  /deep/ .el-radio__inner::after {
    background-color: #7f0404;
    width: 8px;
    height: 8px;
  }

  /deep/ .el-radio__inner {
    border-color: #7f0404;
  }

  /deep/ .el-radio__input.is-checked + .el-radio__label {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }

  /deep/ .el-radio__label {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    margin-right: 30px;
  }

  /deep/ .el-radio {
    margin-right: 0;
  }
  /deep/input:focus {
    box-shadow: none;
  }
}
</style>